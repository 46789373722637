<template>
  <!-- min-width comes from https://vuetifyjs.com/en/components/date-pickers -->
  <v-menu
    ref="dateMenu"
    v-model="dateMenu"
    v-model:return-value="localDate"
    :close-on-content-click="false"
    :disabled="!isActive"
    min-width="290px">
    <template #activator="{ props }">
      <v-text-field
        v-bind="props"
        v-model="localDate"
        :label="label"
        :disabled="!isActive"
        :rules="rules"
        :hint="`${dateFormat} format`"
        class="date-input"
        prepend-icon="event"
        validate-on="blur"
        variant="underlined"
        @blur="formatDate"
        @update:model-value="dateMenu = false"/>
    </template>
    <v-date-picker
      :v-model="localDate"
      :disabled="!isActive"
      no-title
      scrollable
      @update:model-value="(e) => dateChanged(e)"/>
  </v-menu>
</template>

<script>
import moment from 'moment';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATE = moment().format(DEFAULT_DATE_FORMAT);

export default {
  name: 'DateSelector',
  props: {
    isActive: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => 'Date',
    },
    passedDate: {
      type: String,
      default: () => DEFAULT_DATE,
    },
    dateFormat: {
      type: String,
      default: () => DEFAULT_DATE_FORMAT,
    },
    field: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dateMenu: false,
      localDate: DEFAULT_DATE,
    };
  },
  watch: {
    passedDate: {
      handler() {
        this.localDate = this.passedDate;
      },
      immediate: true,
    },
  },
  methods: {
    formatDate() {
      if (!this.formattedDate || !moment(this.formattedDate, this.dateFormat, true).isValid()) {
        this.formattedDate = DEFAULT_DATE;
        this.localDate = DEFAULT_DATE;
      } else {
        this.formattedDate = moment(this.formattedDate).format(this.dateFormat);
        this.localDate = moment(this.formattedDate).format(this.dateFormat);
      }
    },
    dateChanged(e) {
      this.localDate = moment(e).format(this.dateFormat);
      this.$emit('dateChanged', {
        field: this.field,
        date: this.localDate,
      });
    },
  },
};
</script>
